<template>
  <div v-show="!isLoading">
    <b-card>
      <b-card-text>
        <div class="card-title">
          <h4>{{ detail.village.name }}</h4>
        </div>

        <table class="table table-striped my-3">
          <thead>
            <tr class="text-center align-middle">
              <th>TPS</th>
              <th>Surat Suara</th>
              <th>Surat Suara Sah</th>
              <th>Surat Suara Tidak Sah</th>
              <th>Surat Suara Tidak Terpakai</th>
              <th>Detail</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            <tr
              class="text-center align-middle"
              v-for="(item, index) in list.tps"
              :key="index"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.dataSuara ? item.dataSuara.total_surat_suara : '-' }}</td>
              <td>{{ item.dataSuara ? item.dataSuara.total_suara_sah : '-' }}</td>
              <td>{{ item.dataSuara ? item.dataSuara.total_suara_tidak_sah : '-' }}</td>
              <td>{{ item.dataSuara ? item.dataSuara.total_suara_tidak_terpakai : '-' }}</td>
              <td>
                <button
                  :disabled="!item.dataSuara || item.dataSuara.status === 'completed'"
                  @click.prevent="gotoTpsDetail(item)"
                  class="btn btn-sm btn-warning"
                >
                  <font-awesome-icon icon="eye"></font-awesome-icon>
                </button>
              </td>
              <td>
                <span v-if="!item.dataSuara" color="text-warning">
                  Belum Diinput
                </span>
                <span
                  class="text-danger"
                  v-else-if="item.dataSuara.status === 'pending'"
                  >Belum Terverifikasi</span
                >
                <span class="text-success" v-else>Terverifikasi
                  <small v-if="item.dataSuara.status !== 'completed'" style="color: #000;">Sedang Diproses Sistem</small></span>
              </td>
            </tr>
          </tbody>
        </table>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      pageTitle: "",
      level: parseInt(this.$route.params.level),
      levelName: "",
      regionId: "",
      list: {
        villages: [],
        tps: [],
      },
      detail: {
        village: [],
      },
    };
  },
  mounted() {
    this.whenMounted();
  },
  watch: {},
  methods: {
    async gotoTpsDetail(tps) {
      if (tps.dataSuara) {
        if (tps.dataSuara.status === "pending") {
          const lastVerificationWork = await this.$localDb
            .lastVerificationWork()
            .get();
          if (
            lastVerificationWork &&
            (lastVerificationWork.idKelurahan !== this.detail.village.id ||
              lastVerificationWork.regionId !== this.regionId ||
              parseInt(lastVerificationWork.tps) !== tps.name)
          ) {
            await this.$swal
              .fire({
                title: "Anda Sudah Mengisi Data",
                text: "Sistem menemukan anda belum menyelesaikan verifikasi suara di tps lain, apakah anda ingin menghapus data sebelumnya?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ya",
                cancelButtonText: "Tidak",
                reverseButtons: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$router.push(
                    (process.env.NODE_ENV === "production" ? "" : "") +
                      "/kelurahan-suara/kelurahan/" +
                      this.detail.village.id +
                      "/input-suara/calon/" +
                      this.level +
                      "/tps/" +
                      tps.name
                  );
                }
              });
          } else {
            this.$router.push(
              (process.env.NODE_ENV === "production" ? "" : "") +
                "/kelurahan-suara/kelurahan/" +
                this.detail.village.id +
                "/input-suara/calon/" +
                this.level +
                "/tps/" +
                tps.name
            );
          }
        }
        // ionRouter.push('/kelurahan-suara/kelurahan/' + this.detail.village.id + '/input-suara/calon/' + this.level + '/tps/' + tps.name)
      }
    },
    async whenMounted() {
      await this.showLoading("Memuat data...");
      if (this.level === 1) {
        this.levelName = "DPR";
        this.regionId = process.env.VUE_APP_DPR_ID;
      } else if (this.level === 2) {
        this.levelName = "DPRD TK I";
        this.regionId = process.env.VUE_APP_DPRD_ID;
      } else {
        this.levelName = "DPRD TK II";
        this.regionId = process.env.VUE_APP_DPRD_II_ID;
      }

      try {
        const resp = await this.$http.get(
          process.env.VUE_APP_API_URL +
            "area/villages/" +
            this.$route.params.idKelurahan
        );
        this.detail.village = resp.data.data;
        this.pageTitle = resp.data.data.name;
        this.isLoading = false;
      } catch (error) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: serializeErrorMessage(error),
        });
      }

      if (parseInt(this.detail.village.total_tps) > 0) {
        try {
          const resp = await this.$http.get(
            process.env.VUE_APP_API_URL +
              "input-suara/verifikator/suara-petugas/" +
              this.$route.params.idKelurahan +
              "/" +
              this.regionId
          );
          if (resp.data && resp.data.data.length > 0) {
            this.list.tps = [];
            for (
              let index = 1;
              index <= parseInt(this.detail.village.total_tps);
              index++
            ) {
              const dataSuara = resp.data.data.filter((d) => {
                return parseInt(d.tps) === parseInt(index);
              });
              this.list.tps.push({
                name: index,
                dataSuara: dataSuara.length > 0 ? dataSuara[0] : null,
              });
            }
          } else {
            this.list.tps = [];
            for (
              let index = 1;
              index <= parseInt(this.detail.village.total_tps);
              index++
            ) {
              this.list.tps.push({
                name: index,
                dataSuara: null,
              });
            }
          }
        } catch (error) {
          await iziToast.error({
            title: "Error",
            theme: "dark",
            message: serializeErrorMessage(error),
          });
        }
      }
    },
    async showLoading(text = "Memuat Data...") {
      await this.$swal.fire({
        title: "Mohon Tunggu",
        text: text,
        timer: 500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return Promise.resolve("success");
    },
    async openAlert() {
      await this.$swal
        .fire({
          title: "Perhatian!",
          text: "Pastikan anda mengisi data dengan benar dan teliti.",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Mulai",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.value) {
            this.$router.push(
              `/input-suara/calon/${this.$route.params.level}/form`
            );
          }
        });
    },
  },
};
</script>
